// extracted by mini-css-extract-plugin
export var box = "Review-module--box--uABuw";
export var button = "Review-module--button--VqyTa";
export var buttonWrapper = "Review-module--buttonWrapper--hxbV6";
export var container = "Review-module--container--G+6Pe";
export var done = "Review-module--done--Pg+1J";
export var error = "Review-module--error--ICB-V";
export var errors = "Review-module--errors--cWwYT";
export var fieldset = "Review-module--fieldset--waJlg";
export var heading = "Review-module--heading--7e-6M";
export var headingSub = "Review-module--headingSub--qTJ6z";
export var input = "Review-module--input--uNKuX";
export var label = "Review-module--label--6TRgL";
export var legend = "Review-module--legend--il7+F";
export var number = "Review-module--number--ymu9U";
export var score = "Review-module--score--L9iAM";
export var textarea = "Review-module--textarea--d9fWA";